import { Button } from "@/components/Button";
import { Input } from "@/components/Forms/Input";
import { EyeSlashUnfilled, EyeUnfilled } from "@/components/Icons";
import { SocialLoginButtons } from "@/components/SocialLoginButtons";
import { HeaderLayout } from "@/layouts/HeaderLayout";
import { auth } from "@/lib/auth/auth";
import {
    EnjinAuthError,
    authErrorHandler,
    getAuthErrorMessage,
} from "@/lib/auth/enjinAuthError";
import { isAuthError } from "@/lib/auth/isAuthError";
import { useSocialLogins } from "@/lib/auth/useSocialLogins";
import { useUser } from "@/lib/auth/useUser";
import { stringParam } from "@/utils/queryparams";
import { signInWithEmailAndPassword } from "firebase/auth";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import { type ReactNode, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

const LoginPage = (): JSX.Element => {
    const router = useRouter();
    const user = useUser();
    const { googleHandler, facebookHandler } = useSocialLogins();

    const redirect = stringParam(router.query.redirect);
    const isLoggedIn = !!user;
    const redirectUrl = !redirect
        ? "/organisation"
        : decodeURIComponent(redirect);

    useEffect(() => {
        if (isLoggedIn) router.push(redirectUrl);
    }, [isLoggedIn, redirectUrl, router]);

    return (
        <div className="text-blue-grey-900">
            <Head>
                <title>Log In - Courtsite Business</title>
            </Head>
            <div className="m-4 mx-auto max-w-screen-sm rounded-lg bg-white p-4">
                <header className="mb-8">
                    <h1 className="typography-h2 mb-2 font-bold text-blue-grey-900">
                        Log In
                    </h1>
                    <p>
                        Don&apos;t have a Courtsite account yet?{" "}
                        <Link
                            href={{ pathname: "/signup", query: router.query }}
                        >
                            Signup here.
                        </Link>
                    </p>
                </header>
                <EmailLoginForm
                    onSubmit={async (v) => {
                        await signInWithEmailAndPassword(
                            auth,
                            v.email,
                            v.password,
                        ).catch((error) => {
                            let authErr: EnjinAuthError;
                            if (!isAuthError(error)) {
                                authErr = new EnjinAuthError(
                                    "GFBUH",
                                    "An unknown error occurred, please try again later.",
                                    { cause: error },
                                );
                            } else {
                                authErr = getAuthErrorMessage(error);
                            }
                            authErrorHandler(authErr);
                        });
                    }}
                    extraAction={
                        <Link
                            href={{
                                pathname: "/resetpassword",
                                query: { redirect: router.asPath },
                            }}
                        >
                            Forgot your password?
                        </Link>
                    }
                />
                <div className="my-8 flex items-center gap-6">
                    <div className="h-px flex-1 bg-blue-grey-50" />
                    <div className="typography-sub">or continue with</div>
                    <div className="h-px flex-1 bg-blue-grey-50" />
                </div>
                <SocialLoginButtons
                    onClickGoogle={() =>
                        googleHandler().catch(authErrorHandler)
                    }
                    onClickFacebook={() =>
                        facebookHandler().catch(authErrorHandler)
                    }
                />
            </div>
        </div>
    );
};

LoginPage.Layout = HeaderLayout;

export default LoginPage;

type EmailLoginFormValues = { email: string; password: string };
export const EmailLoginForm = (props: {
    onSubmit: (v: EmailLoginFormValues) => void | Promise<void>;
    extraAction?: ReactNode;
}): JSX.Element => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isShowPass, setShowPass] = useState(false);
    const { register, handleSubmit, formState } = useForm<EmailLoginFormValues>(
        { defaultValues: { email: "", password: "" } },
    );

    return (
        <form
            onSubmit={handleSubmit(async (v) => {
                setIsSubmitting(true);
                await props.onSubmit(v);
                setIsSubmitting(false);
            })}
            className="typography-sub grid grid-cols-[max-content_1fr] items-baseline gap-2"
        >
            <FormItem label="Email" error={formState.errors.email?.message}>
                <Input
                    type="email"
                    autoFocus
                    {...register("email", { required: "Email is required" })}
                />
            </FormItem>
            <FormItem
                label="Password"
                error={formState.errors.password?.message}
            >
                <Input
                    type={isShowPass ? "text" : "password"}
                    {...register("password", {
                        required: "Password is required",
                    })}
                    suffixChild={
                        <Button
                            variant="text"
                            size="zero"
                            type="button"
                            onClick={() => setShowPass((v) => !v)}
                        >
                            {isShowPass ? (
                                <EyeUnfilled />
                            ) : (
                                <EyeSlashUnfilled />
                            )}
                        </Button>
                    }
                />
            </FormItem>
            <div className="col-span-full flex items-baseline justify-between">
                <div>{props.extraAction}</div>
                <Button type="submit" disabled={isSubmitting}>
                    Log In
                </Button>
            </div>
        </form>
    );
};

const FormItem = (props: {
    label?: ReactNode;
    children?: ReactNode;
    error?: ReactNode;
}): JSX.Element => (
    <label className="contents">
        <span className="text-right">{props.label}</span>
        {props.children}
        <span className="col-span-full text-destructive">{props.error}</span>
    </label>
);
